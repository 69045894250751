import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import { BToast } from 'bootstrap-vue'
import Vue from 'vue'
// import { toast } from BVToastPlugin
// import store from '@/store'


const service = axios.create({
    baseURL: (process.env.NODE_ENV !== 'production' ? '/local' : '') + process.env.VUE_APP_BASE_URL, // url = base url + request url
    timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        let { data = {}, method, removeSiteId = false } = config
        if (method == 'post') {
            let siteObj = !removeSiteId ? { siteId: data.siteId || 1 } : {}
            config.data = typeof data == 'object' ? { ...siteObj, ...data } : siteObj
        }
        return config
    },
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        const toast = new BToast()
        if (res.code !== 0) {
            if ([-1, 50008, 50012, 50014].includes(res.code)) {
                toast.$bvToast.toast(res.msg || '请求出错啦', {
                    title: `提示`,
                    variant: 'danger',
                    headerClass: 'show-title',
                    noCloseButton: true,
                    toaster: 'b-toaster-top-center',
                    append: false,
                    autoHideDelay: 2000,
                    solid: true
                })
                return Promise.reject(res.msg || 'Error')
            }
        } else {
            return res.data
        }
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error.msg)
    }
)

export default service
