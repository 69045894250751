import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false
import store from './store'
// Vue.use(IconsPlugin)
// import './assets/styles/bootstrap.scss'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import './assets/styles/common.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import './assets/js/flexible.js'
import '@/assets/styles/editor.scss'
import './assets/styles/reset.css'
import './assets/styles/styles.scss'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})
