<template>
  <div class="right-wrapper">
    <div class="pro_hot wrapper">
      <i class="hot_ico"><img src="../../../assets/images/hot_ico01.png" /></i>
      <h1>本周优质产品</h1>
      <ul>
        <li
          v-for="item in productList"
          :key="item.id"
        >
          <p
            class="pro_txt fs"
            @click="toDetail(item)"
          ><i></i><a>{{ item.productName }}</a></p>
          <div class="hod_proxq">
            <div class="fl hod_proxq_l">
              <p>投资期限：<span>{{ item.productTerm }}个月</span></p>
              <p>投资方向：<span>{{ directions[item.direction - 1] }}</span></p>
            </div>
            <div class="fl">
              <p>业绩基准：<span>{{ item.yield }}%/年</span></p>
              <p>起投金额：<span class="hod_xqspan">{{ item.minMoney }}万元</span></p>
            </div>
          </div>
        </li>

      </ul>
      <p
        class="more"
        @click="toPrdtList"
      ><a>查看更多产品&gt;&gt;</a></p>
    </div>
    <!-- <div class="pro_hot wrapper">
      <i class="hot_ico"><img src="../../../assets/images/hot_ico02.png"></i>
      <h1>推荐信托产品</h1>
      <ul>
        <li>
          <p class="pro_txt"><i></i><a>央企信托-527号江苏盐城</a></p>
          <div class="hod_proxq">
            <div class="fl hod_proxq_l">
              <p>投资期限：<span>24个月</span></p>
              <p>融资规模：<span>2亿</span></p>
            </div>
            <div class="fl">
              <p>过往业绩：<span>7.2%</span></p>
              <p>项目类型：<span class="hod_xqspan">永续债</span></p>
            </div>
          </div>
        </li>
        <li>
          <p class="pro_txt"><i></i><a>央企信托-189号江苏盐城</a></p>
          <div class="hod_proxq">
            <div class="fl hod_proxq_l">
              <p>投资期限：<span>24个月</span></p>
              <p>融资规模：<span>2亿</span></p>
            </div>
            <div class="fl">
              <p>过往业绩：<span>7.2%</span></p>
              <p>项目类型：<span class="hod_xqspan">永续债</span></p>
            </div>
          </div>
        </li>

      </ul>
      <p class="more"><a>查看信托产品&gt;&gt;</a></p>
    </div> -->
  </div>
</template>

<script>
import { getProductList } from '@/api/api'
import AuthModal from '@/plugins/AuthModal'
import { directions } from '@/utils/options'
export default {
  data () {
    return {
      directions,
      productList: []
    }
  },
  created () {
    this.getHotList()
  },
  methods: {
    getHotList () {
      getProductList({
        productType: 1,
        recPosition: 2,
        page: 1,
        pageSize: 3
      }).then(({ content }) => {
        this.productList = content
      })
    },
    toPrdtList () {
      this.$router.push('/product')
    },
    linkDetail (id) {
      this.$router.push({
        path: '/product/detail',
        query: {
          id
        }
      })
    },
    toDetail ({ id, productName }) {
      let authRes = this.$store.state.authRes
      if (authRes && Object.keys(authRes).length) return this.linkDetail(id)
      AuthModal({
        productName,
        successCb: () => {
          this.linkDetail(id)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  border-top: 8px solid #e95604;
  background: #fff;
  padding-bottom: 20px;
}
.right-wrapper {
  flex: 0 0 27%;
  .pro_hot {
    position: relative;
    margin-bottom: 50px;
    background: #fff;
    .hot_ico {
      position: absolute;
      top: -12px;
      left: 20%;
    }
    h1 {
      text-align: center;
      text-indent: 20%;
      color: #ea5504;
      font-size: 25px;
      font-weight: 700;
      line-height: 85px;
    }
    ul {
      padding: 0 8%;
      li {
        padding-bottom: 20px;
        border-bottom: 1px dashed #d0d0d0;
        margin-bottom: 20px;
        .pro_txt {
          cursor: pointer;
          align-items: flex-start;
          i {
            display: inline-block;
            position: relative;
            top: 4px;
            width: 12px;
            height: 24px;
            border-left: 3px solid #898989;
          }
          a {
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
            color: #4c4a48;
          }
        }
        .hod_proxq {
          margin-top: 15px;
          overflow: hidden;
          p {
            font-size: 13px;
            color: #555;
            line-height: 25px;
            span {
              color: #ea5504;
            }
          }
          .hod_proxq_l {
          }
        }
      }
    }
    .more {
      text-align: right;
      font-size: 14px;
      position: relative;
      padding: 0 20px;
      cursor: pointer;
      a {
        color: #ea5504;
      }
    }
  }
}
</style>