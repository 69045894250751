<template>
  <div class="left-wrapper wrapper">
    <b-card no-body>
      <b-tabs card>
        <b-tab
          :title="item"
          :active="currentTab == (index + 1)"
          v-for="(item, index) in tabsData"
          :key="index"
          @click="getList(index ? 4 : 3)"
        >
          <div class="list">
            <div
              class="list_item fs"
              v-for="i in listData"
              :key="i.id"
              @click="toDetail(i.id)"
            >
              <div class="images">
                <img :src="i.header" />
              </div>
              <div class="r_content">
                <div class="title">
                  {{ i.title }}
                </div>
                <p>{{ i.description }}</p>
                <div class="time">{{ i.time }}</div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-pagination
      v-model="pagination.page"
      :total-rows="pagination.total"
      :per-page="pagination.pageSize"
      align="center"
      :hide-goto-end-buttons="true"
      prev-text="上一页"
      next-text="下一页"
    ></b-pagination>
  </div>
</template>

<script>
import { articlePage } from '@/api/api'
export default {
  data () {
    return {
      tabsData: ['最新资讯', '信托百科'],
      listData: [],
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0
      },
    }
  },
  computed: {
    currentTab () {
      return this.$route.query.tab ? Number(this.$route.query.tab) : 1
    }
  },
  created () {
    this.getList(this.currentTab == 1 ? 3 : 4)
  },
  methods: {
    getList (cateId) {
      articlePage({
        cateId,
        page: 1,
        pageSize: 8,
      }).then(res => {
        this.listData = res.content || []
        this.pagination.total = res.total
      })
    },
    toDetail (id) {
      this.$router.push({ path: '/news/detail', query: { id } })
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep .card {
  border: none;
  border-radius: 0;
  .tabs {
    .card-header {
      padding: 0px 0px;
      border-radius: 0;
      background: #fff;
    }
    .card-header-tabs {
      margin: 0;
    }
    .nav-link {
      width: 145px;
      height: 60px;
      text-align: center;
      padding: 0;
      border-radius: 0px;
      color: #898989;
      font-size: 20px;
      font-weight: bold;
      line-height: 60px;
      border: none;
      display: inline-block;
      &.active {
        color: #e85603;
        border-bottom: 4px solid #f6b37f;
      }
    }
    .card-body {
      width: 90%;
      margin: 0 auto;
    }
  }
}
::v-deep .pagination {
  li {
    margin: 0 5px;
    &.active {
      .page-link {
        background: #ea5504;
        border: 1px solid #ea5504;
        color: #fff;
      }
    }
    .page-link {
      padding: 0 15px;
      line-height: 32px;
      background: #fff;
      border: 1px solid #d9d9d9;
      box-shadow: none;
      margin: 0 5px;
      border-radius: 3px;
      color: #555;
      display: inline-block;
    }
  }
}
.left-wrapper {
  flex: 0 0 70.5%;
  .list {
    .list_item {
      border-bottom: 1px dashed #d0d0d0;
      padding-bottom: 15px;
      margin-bottom: 15px;
      .images {
        border: 2px solid #c9c9c9;
        margin: 5px 20px 0 0;
        img {
          width: 182.8px;
          display: block;
        }
      }
      .r_content {
        .title {
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
          color: #4c4a48;
          cursor: pointer;
          margin-bottom: 10px;
          &:hover {
            color: #ea5504;
          }
        }
        p {
          font-size: 14px;
          line-height: 23px;
          color: #4c4a48;
        }
        .time {
          color: #717171;
          font-size: 14px;
          margin-top: 5px;
          text-align: right;
        }
      }
    }
  }
}
</style>