<template>

  <div class="filter-conditons">
    <h3>信托产品</h3>
    <div class="condictions">
      <div
        class="condt_item fs"
        v-for="(item, index) in condictions"
        :key="index"
      >
        <label>{{item.title}}：</label>
        <ul class="fs">
          <li
            :class="{ active: formData[item.key] == '' }"
            @click="selectHandle('', item.key)"
          >不限</li>
          <li
            :class="{ active: formData[item.key] == kndex + 1 }"
            @click="selectHandle(kndex + 1, item.key)"
            v-for="(ktem, kndex) in item.list"
            :key="kndex"
          >{{ ktem }}</li>
        </ul>
      </div>
      <div class="condt_item fs">
        <label>筛选条件：</label>
        <ul class="fs">
          <li
            class="fc refresh"
            @click="resetHandle"
          ><b-icon
              icon="arrow-counterclockwise"
              font-scale="1"
            ></b-icon>清空条件</li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import {
  payTypes,
  benefits,
  dateRanges,
  zx_benefits,
  recPositions,
  productStatus,
  directions
} from '@/utils/options'
const formData = {
  recPosition: '',
  productStatus: '',
  benefit: '',
  minMoney: '',
  dateRange: '',
  direction: '',
  payWay: ''
}
export default {
  data () {
    return {
      formData: {
        ...JSON.parse(JSON.stringify(formData))
      }
    }
  },
  computed: {
    condictions () {
      return [
        {
          title: '推荐类型',
          key: 'recPosition',
          list: recPositions
        },
        {
          title: '募集状态',
          key: 'productStatus',
          list: productStatus
        },
        {
          title: '产品收益',
          key: 'benefit',
          list: location.hash.indexOf('zx_product') > -1 ? zx_benefits : benefits
        },
        {
          title: '起投金额',
          key: 'minMoney',
          list: [
            '100万以内',
            '100万(含)-300万',
            '300万以上(含)'
          ]
        },
        {
          title: '投资期限',
          key: 'dateRange',
          list: dateRanges
        },
        {
          title: '投资方向',
          key: 'direction',
          list: directions
        },
        {
          title: '付息方式',
          key: 'payWay',
          list: payTypes
        },
      ]
    }
  },
  methods: {
    selectHandle (value, key) {
      this.$set(this.formData, key, value)
      this.$emit('search', this.formData)
    },
    resetHandle () {
      this.$set(this, 'formData', formData)
      this.$emit('search', this.formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-conditons {
  margin-bottom: 10px;
  h3 {
    font-size: 21px;
    line-height: 43px;
    font-weight: bold;
    padding-bottom: 15px;
    margin: 0px 0 15px 0;
    border-bottom: 10px #ff8b65 solid;
  }
  .condictions {
    border: 1px #e5e5e5 solid;
    padding-top: 10px;
    .condt_item {
      label {
        font-size: 16px;
        flex: 0 0 100px;
        line-height: 40px;
        text-align: right;
        font-weight: normal;
        color: #333;
        padding: 0px 10px 0px 0;
        border-right: 1px #e5e5e5 solid;
      }
      ul {
        padding: 5px 10px 5px 10px;
        li {
          margin-left: 12px;
          height: 30px;
          overflow: hidden;
          font-size: 15px;
          color: #333;
          cursor: pointer;
          padding: 0 8px;
          text-align: center;
          line-height: 28px;
          word-break: break-all;
          word-wrap: break-word;
          &.refresh {
            color: #888;
          }
          &.active {
            display: inline-block;
            color: #ff8b65 !important;
            border: 1px #ff8b65 solid;
          }
        }
      }
    }
  }
}
</style>