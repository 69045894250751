<template>
  <div class="ecellect-goods">
    <div class="top_txt fc">
      <h1>优质产品推荐</h1>
      <span @click="showMore"><a>查看更多产品&gt;&gt;</a></span>
    </div>
    <div class="goods">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="400000"
        controls
        indicators
        background="#fff"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          v-for="i in pageNum"
          :key="i"
        >
          <div class="tempWrap">
            <ul>
              <li
                class="fc"
                v-for="item in productList.slice(i * 4 - 4, i * 4)"
                :key="item.id"
              >
                <div class="hod_licon">
                  <div class="hod_t">
                    <a
                      title="央企信托-JSFN集合资金信托计划"
                      @click="contact(item)"
                    >
                      {{ item.productName }}
                    </a>

                  </div>
                  <p class="shouy">{{ item.yield }}%/年</p>
                  <p class="shouy_span">业绩基准</p>
                  <div class="mk_p">
                    <p class="mk_p1">门槛：<span>{{ item.minMoney }}万元</span></p>
                    <p class="mk_p1">期限：<span>{{ item.productTerm }}{{ item.productTerm && item.productTerm.length >= 8 ? '' : '个月' }}</span></p>
                  </div>
                  <div class="clear"></div>
                  <div class="hod_desc">
                    <span>{{ item.productAbstract }}</span>
                  </div>
                  <a
                    @click="contact(item)"
                    class="hod_zixun"
                    data_id="2051"
                  >查看详情</a>

                </div>
              </li>
            </ul>
          </div>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AuthModal from '@/plugins/AuthModal'
import { getProductList } from '@/api/api'
import { productStatus, directions } from '@/utils/options'
export default {
  data () {
    return {
      productList: [],
      pageNum: 0,
      slide: 0,
      sliding: null,
      productStatus, directions
    }
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapState(['authRes'])
  },
  methods: {
    getList () {
      getProductList({
        recPosition: 1,
        page: 1,
        productType: 1,
        pageSize: 8
      }).then(({ content, total }) => {
        total = total > 8 ? 8 : total
        this.productList = content
        this.pageNum = Math.ceil(total / 4)
      })
    },
    showMore () {
      this.$router.push('/product')
    },
    toDetail (id) {
      this.$router.push({
        path: '/product/detail',
        query: {
          id
        }
      })
    },
    contact ({ id, productName }) {
      if (this.authRes && Object.keys(this.authRes).length) return this.toDetail(id)
      AuthModal({
        productName,
        successCb: () => {
          this.toDetail(id)
        }
      })
    },
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin padding {
  margin-left: 6%;
  margin-right: 6%;
}
@mixin banner_button {
  width: 1.24vw;
  height: 2.31vw;
  background-position: 0 0;
  opacity: 1;
  background-size: contain;
}
::v-deep .carousel {
  text-shadow: none !important;
  .carousel-caption {
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
    padding: 0;
    z-index: 0;
    bottom: 0;
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: auto;
    opacity: 1;
    margin: 0;
    padding: 0;
  }
  .carousel-control-prev {
    left: -1.48vw;
  }
  .carousel-control-next {
    right: -1.48vw;
  }
  .carousel-control-next-icon {
    background-image: url(../../../assets/images/arrow_right.png);
    @include banner_button;
  }
  .carousel-control-prev-icon {
    background-image: url(../../../assets/images/arrow_left.png);
    @include banner_button;
  }
  .carousel-indicators {
    margin-bottom: 1.5vw;
    bottom: -60px;
    li {
      background: #ccc;
      border: 1px solid #ccc;
      height: 15px;
      width: 15px;
      color: #eee;
      margin: 0 5px;
      border-radius: 50%;
      &.active {
        background: #be181f;
        border: 1px solid #be181f;
      }
    }
  }
}
.ecellect-goods {
  margin-top: 35px;
}
.goods {
  margin-top: 30px;
  padding-bottom: 60px;
  .tempWrap {
    overflow: hidden;
    li {
      width: 25%;
      padding: 12px 0;
      float: left;
      p {
        margin-bottom: 0;
      }
      .hod_licon {
        background: #fff;
        width: 90%;
        padding: 16px 0 36px 0;
        border: 1px solid #e1e1e1;
        border-radius: 20px;
        box-shadow: 0 1px 8px 0 rgba(66, 66, 66, 0.35);
        -moz-box-shadow: 0 1px 5px 0 rgba(66, 66, 66, 0.35);
        -webkit-box-shadow: 0 1px 5px 0 rgba(66, 66, 66, 0.35);
      }
      .hod_t {
        height: 32px;
        line-height: 32px;
        overflow: hidden;
        font-weight: bold;
        @include padding;
      }

      .hod_t a {
        display: block;
        font-size: 16px;
        cursor: pointer;
        color: #4c4a48;
      }
      .clear {
        clear: both;
      }
      .shouy {
        text-align: center;
        font-size: 32px;
        color: #fb6436;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 0;
      }

      .shouy_span {
        text-align: center;
        font-size: 14px;
        color: #999;
        margin-top: 10px;
      }
      .mk_p {
        margin-top: 28px;
      }

      .mk_p p {
        font-size: 16px;
        color: #676767;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        float: left;
        width: 49.5%;
        text-align: center;
      }

      .mk_p1 span {
        color: #fb6436;
        font-weight: bold;
      }
      .hod_desc {
        margin-top: 15px;
        color: #333;
        border-top: 1px dashed #ccc;
        padding: 16px 0;
        height: 80px;
        overflow: hidden;
        line-height: 24px;
        @include padding;
      }
      .hod_zixun {
        display: block;
        margin: 35px auto 0;
        text-align: center;
        cursor: pointer;
        width: 68%;
        height: 35px;
        line-height: 35px;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        background: url("../../../assets/images/btn01.png") no-repeat center;
        background-size: 100% 100%;
        border-radius: 20px;
        text-decoration: none;
        &:hover {
          color: #fff;
          background: url("../../../assets/images/btn02.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>