<template>
  <div class="banner-slides">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        v-for="(item, index) in banner"
        :key="index"
        @click.native="toDetail(item.id)"
        :img-src="item.image"
      ></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      slide: 0,
      sliding: null
    }
  },
  computed: {
    ...mapState(['siteData']),
    banner () {
      let { banner } = this.siteData
      let arr = banner && banner.length ? JSON.parse(banner) : []
      return arr.map(i => typeof i == 'object' ? i : { image: i })
    }
  },
  methods: {
    onSlideStart (slide) {
      this.sliding = true
    },
    onSlideEnd (slide) {
      this.sliding = false
    },
    toDetail (id) {
      if (!id) return
      this.$router.push(`/product/detail?id=${id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin banner_button {
  width: 3vw;
  height: 6vw;
  opacity: 0.6;
  background-position: center center;
  background-size: 60% 60%;
  background-color: #000;
}
::v-deep .carousel .carousel-control-next-icon {
  background-image: url(../../../assets/images/banner_right.png);
  @include banner_button;
}
::v-deep .carousel-control-prev-icon {
  background-image: url(../../../assets/images/banner_left.png);
  @include banner_button;
}
::v-deep .carousel-indicators {
  margin-bottom: 1.6vw;
  li {
    background: #ccc;
    border: 1px solid #ccc;
    height: 15px;
    margin: 0 5px;
    width: 15px;
    color: #eee;
    border-radius: 50%;
    &.active {
      background: #be181f;
      border: 1px solid #be181f;
    }
  }
}
::v-deep .img-fluid {
  // height: 2.415rem;
}
.banner-slides {
  // max-width: 1440px;
  margin: 0 auto;
  .carousel {
    .carousel-control-next {
    }
  }
}
</style>