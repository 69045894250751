<template>
  <div class="product-detail">
    <b-container fluid="xl">
      <div class="bread_crumb"><strong>您的位置：</strong>{{productData.productType == 1 ? '信托' : '政信' }}>{{productData.productName }}</div>
      <div class="wrap">
        <div class="proxq_con fb">
          <div class="proxq_js">
            <div class="pro_name fs">
              <span class="xq_title fs">{{productData.productName }}<i class="state">进行中</i></span>
            </div>
            <div class="clear"></div>
            <div class="proxq_sx fb">
              <div class="proxq_r">
                <div class="pro_sxtype fb">
                  <div class="pro_ls">
                    <span class="c_sy">{{ productData.yield }}%/年</span>
                    <p>业绩基准</p>
                  </div>
                  <div class="pro_ls m_box">
                    <span>{{ productData.productTerm }}个月</span>
                    <p>产品期限</p>
                  </div>
                  <div class="pro_ls">
                    <span>{{ productData.minMoney }}万元</span>
                    <p>投资起点</p>
                  </div>
                </div>
              </div>
              <div class="proxq_rzx">
                <a
                  class="prorxq_zx"
                  @click="contact"
                >立即咨询</a>
              </div>
            </div>
            <p class="pro_dp">产品点评：<br>{{ productData.productAbstract }}</p>
          </div>
          <div class="proxq_zx">
            <div class="proxq_xx">
              <p class="prddtl-xtrgp">立即预约</p>
              <p class="y_txt">10秒钟快速预约，体验1对1咨询顾问服务</p>
              <div class="clear"></div>
              <div class="prddtl-xtrgform">
                <div class="relative">
                  <input
                    name=""
                    type="text"
                    id="prname"
                    v-model="formData.userName"
                    placeholder="请输入您的中文姓名"
                    class="xtlst-form-ipt1"
                  >
                </div>
                <div class="relative">
                  <input
                    name=""
                    type="text"
                    id="prtel"
                    maxlength="11"
                    v-model="formData.phoneNum"
                    placeholder="请输入你的11位手机号码"
                    class="xtlst-form-ipt1"
                  >
                  <span class="xqzx_error"></span>
                </div>
                <div
                  class="relative"
                  style="position:relative;"
                >
                  <input
                    type="text"
                    class="rig_input-code xtlst-form-ipt1"
                    maxlength="6"
                    v-model="formData.veriCode"
                    placeholder="验证码"
                  >
                  <span
                    class="send_btn fc"
                    @click="sendMsg"
                  >{{ msgTime ? msgTime : '发送验证码' }}</span>
                  <!-- <span
                    class="rig_code user-form-code"
                    title="点击切换"
                    style="font-size: 17px; position: absolute; right: 0px; height: 34px; line-height: 34px; background: rgb(208, 217, 249); width: 80px; padding: 0px 5px; text-align: center; display: inline-block; cursor: pointer; opacity: 0.6;"
                  >
                  <span style="padding:0 2px;color:#135784;display:inline-block;transform:rotate(-12deg);">C</span><span style="padding:0 2px;color:#e3730f;display:inline-block;transform:rotate(-7deg);">8</span><span style="padding:0 2px;color:#74675a;display:inline-block;transform:rotate(-4deg);">2</span><span style="padding:0 2px;color:#123345;display:inline-block;transform:rotate(14deg);">F</span><input
                      placeholder=""
                      value="C82F"
                      type="hidden"
                    ></span> -->
                </div>
                <input
                  name=""
                  type="button"
                  id="prMsg--"
                  value="立即预约"
                  @click="submit"
                  class="xtlst-form-btn1"
                  data-category-type="trust_short_name"
                >
              </div>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="info_con">
          <div class="t_box">
            <span class="tt">产品详情</span>
            <span class="tk"></span>
          </div>
          <div class="clear"></div>
          <div class="basic_box">
            <p><i class="t_i"></i> <span class="t_s">基本信息</span><label>（本网站仅做展示，不承诺信托的最低收益）</label></p>
            <div class="clear"></div>
            <table>
              <tbody>
                <tr>
                  <td class="w130">名称产品</td>
                  <td class="w470">{{ productData.productName }}</td>
                  <td class="w130">产品状态</td>
                  <td class="w470">{{ productStatus[productData.productStatus - 1] }}</td>
                </tr>
                <tr>
                  <td class="w130">产品期限</td>
                  <td class="w470">{{ productData.productTerm }}个月</td>
                  <!-- <td class="w470">{{ dateRanges[productData.dateRange - 1] }}</td> -->
                  <td class="w130">付息方式</td>
                  <td class="w470">{{ payTypes[productData.payWay - 1] }}</td>
                </tr>
                <tr>
                  <td class="w130">产品规模</td>
                  <td class="w470">{{ productData.missionScale }}亿元</td>
                  <td class="w130">投资领域</td>
                  <td class="w470">{{ directions[productData.direction - 1] }}</td>
                </tr>
                <tr>
                  <td class="w130">认购起点</td>
                  <td
                    class="w470"
                    :colspan="productData.productType == 2 ? '3' : '1'"
                  >{{ productData.minMoney }}万元</td>
                  <td
                    class="w130"
                    v-if="productData.productType == 1"
                  >发行机构</td>
                  <td
                    class="w470"
                    v-if="productData.productType == 1"
                  >
                    {{ productData.institution }}
                  </td>
                </tr>
                <tr>
                  <td class="w130">业绩基准</td>
                  <td
                    colspan="3"
                    class="w1070"
                  >
                    <p
                      class="center"
                      v-html="productData.reference ? productData.reference.replace(/\n/g, '<br/>') : ''"
                    ></p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p><i class="t_i"></i> <span class="t_s">项目概述</span></p>
            <div class="gs_box w-e-text-container">
              <div
                data-slate-editor
                v-html="productData.productDesc"
              ></div>
            </div>
            <!-- <div class="pro_xq">
                <h2>资金用途：</h2>
                <div>
                  <p>资金用于投资融资方发行的永续债权益投资计划</p>
                </div>
              </div>
              <div class="pro_xq">
                <h2>还款来源：</h2>
                <div>
                  <p style="white-space: normal;">1、融资人的经营收入；<br></p>
                  <p style="white-space: normal;">2、担保人的经营收入。</p>
                </div>
              </div>

              <div class="pro_xq">
                <h2>风控措施：</h2>
                <div>
                  <p>1、盐城海瀛控股集团有限公司提供不可撤销连带责任保证担保</p>
                </div>
              </div> -->
            <div class="clear"></div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { productStatus, directions, payTypes, dateRanges, benefits, institutions } from '@/utils/options'
import { validate } from '@/utils'
import { addAuthInfo, getProductDt, sendMessage } from '@/api/api'
import AuthModal from '@/plugins/AuthModal'
const formData = {
  userName: '',
  phoneNum: '',
  veriCode: ''
}
export default {
  data () {
    return {
      productStatus, directions, payTypes, benefits, dateRanges, institutions,
      formData: {
        ...formData
      },
      productData: {},
      msgTime: 0
    }
  },
  created () {
    getProductDt(this.$route.query.id).then((res) => {
      this.productData = res
      if (!this.authRes) {
        AuthModal({
          productName: this.productData.productName,
          closeCb: () => {
            this.$router.go(-1)
          }
        })
      }
    })
  },
  computed: {
    ...mapState(['authRes']),
  },

  methods: {
    contact () {
      AuthModal({
        productName: this.productData.productName,
      })
      // window.open('https://affim.baidu.com/cps5/chatIndex?siteToken=34707f984c1b7607475160dd22d735fd&speedLogId=17028119772338ac6_1702811977233_00066&bid=1070903271012777921&eid=26489348&reqParam=%7B%22from%22%3A0,%22sid%22%3A%22-100%22,%22tid%22%3A%22-1%22,%22ttype%22%3A1,%22siteId%22%3A%2212777921%22,%22userId%22%3A%2226489348%22,%22pageId%22%3A0%7D', '_blank')
    },
    sendMsg () {
      let regPhone = /^1[3456789]\d{9}$/
      if (!regPhone.test(this.formData.phoneNum)) {
        return this.$bvModal.msgBoxOk('请输入正确手机号', {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      if (this.msgTime) return
      sendMessage(this.formData.phoneNum).then(() => {
        this.$bvModal.msgBoxOk('验证码发送成功', {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
        this.msgTime = 60
        this.countDown()
      })
    },
    countDown () {
      setTimeout(() => {
        this.msgTime--
        if (!this.msgTime) return
        this.countDown()
      }, 1000)
    },
    submit () {
      let errMsg = validate(this.formData)
      if (errMsg) {
        return this.$bvModal.msgBoxOk(errMsg, {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      addAuthInfo(this.formData).then(() => {
        this.$bvModal.msgBoxOk('提交成功', {
          title: '提示',
          size: 'sm',
          centered: true,
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
        this.$store.commit('setAuthRes', { createTime: Date.now(), ...this.formData })
        // this.$bvToast.toast('提交成功', {
        //   title: `提示`,
        //   variant: 'success',
        //   noCloseButton: true,
        //   toaster: 'b-toaster-top-center',
        //   append: false,
        //   autoHideDelay: 2000,
        //   solid: true
        // })
        this.formData = {
          ...formData
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .gs_box {
  > p {
    margin-bottom: 6px;
  }
  p {
    font-size: 17px;
    line-height: 36px;
    * {
      font-size: 17px !important;
      color: #555 !important;
    }
  }
}
.product-detail {
  margin-bottom: 30px;
  .bread_crumb {
    padding-top: 25px;
    font-size: 14px;
    color: #666;
  }
}
.wrap {
  margin: 0 auto;
  .clearfix {
    clear: both;
  }
  .proxq_con {
    margin-top: 25px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 0 5px #e5e5e5;
    -webkit-box-shadow: 0 0 5px #e5e5e5;
    -moz-box-shadow: 0 0 5px #e5e5e5;
    -o-box-shadow: 0 0 5px #e5e5e5;
    border-right: 0;
    margin-bottom: 25px;
    border: 1px #d2d2d2 solid;
  }

  .proxq_js {
    padding: 20px 20px 20px 20px;
    width: 67%;
    height: 100%;
    color: #666;
    border-right: 1px solid #d2d2d2;
  }

  .pro_name .xq_title {
    font-size: 24px;
    max-width: 100%;
    text-indent: 8px;
  }

  .proxq_sx {
    width: 100%;
    overflow: hidden;
    padding: 30px 0px 20px 0px;
    margin: 20px 0 10px;
    border-top: 1px #d2d2d2 solid;
    border-bottom: 1px #d2d2d2 solid;
  }

  .proxq_r {
    flex: 0 0 70%;
    padding-left: 60px;
  }

  .pro_sxtype {
    width: 100%;
    height: 65px;
    overflow: hidden;
  }

  .pro_ls {
    float: left;
    text-align: center;
  }

  .proxq_sx .pro_ls span {
    font-size: 29px;
    white-space: nowrap;
    color: #666;
    padding-top: 5px;
  }

  .proxq_sx .pro_ls p {
    font-size: 14px;
    color: #656565;
    padding-top: 3px;
    line-height: 25px;
  }

  .proxq_sx span.c_sy {
    color: #ff6a4f;
  }

  .m_box {
    // padding: 0 50px;
    // margin: 0 48px;
  }
  .send_btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    font-size: 13px;
    cursor: pointer;
    white-space: nowrap;
    height: 34px;
    line-height: 34px;
    background: #e85603;
    color: #fff;
  }
  .pro_dp {
    width: 100%;
    overflow: auto;
    height: 70px;
    font-size: 14px;
    margin-top: 10px;
    color: #656565;
    text-align: justify;
    line-height: 22px;
  }

  .proxq_rzx {
    flex: 0 0 25%;
  }

  .proxq_rzx a {
    font-size: 18px;
    cursor: pointer;
    border: 1px solid #ff6a4f;
    padding: 5px 40px;
    color: #ff6a4f;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
  }

  .proxq_rzx a:hover {
    background: #ff6a4f;
    color: #fff;
  }

  .proxq_zx {
    width: 340px;
    height: 279px;
    right: 0;
    top: -16px;
    text-align: center;
    padding-top: 10px;
  }

  .y_txt {
    color: #888;
    line-height: 35px;
    font-size: 14px;
  }

  .prddtl-xtrgp {
    width: 100%;
    height: 32px;
    font-size: 17px;
    color: #fff;
    line-height: 35px;
    text-align: center;
    color: #ff6a4f;
  }
  .relative {
    width: 280px;
    height: 36px;
    border: 1px #d2d2d2 solid;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .xtlst-form-ipt1 {
    border: 0;
    height: 34px;
    line-height: 34px;
    text-indent: 8px;
    width: 275px;
    color: #a9a9a9;
    float: right;
    font-size: 14px;
    padding-left: 5px;
  }

  .xtlst-form-btn1 {
    height: 40px;
    line-height: 38px;
    outline: 0;
    border: 0;
    width: 280px;
    cursor: pointer;
    border: 1px solid #fff;
    font-size: 16px;
    background: #ff6a4f;
    color: #fff;
    margin-top: 10px;
  }

  .info_con {
    width: 100%;
    border: 1px #d2d2d2 solid;
  }

  .info_con .t_box {
    width: 100%;
    height: 58px;
    position: relative;
    margin-bottom: 15px;
  }

  .info_con .t_box .tt {
    width: 12.91%;
    height: 58px;
    text-align: center;
    font-size: 18px;
    line-height: 52px;
    display: block;
    display: block;
    border-right: 1px #d2d2d2 solid;
    border-top: 3px #ff6a4f solid;
    margin-top: -2px;
    z-index: 99;
  }

  .info_con .t_box .tk {
    width: 87%;
    height: 58px;
    position: absolute;
    top: 0px;
    right: 0;
    border-bottom: 1px #d2d2d2 solid;
    display: block;
  }

  .info_con .basic_box {
    width: 95%;
    margin: 0 auto;
  }

  .info_con .t_i {
    border-left: 5px #ff6a4f solid;
    display: inline-block;
    width: 10px;
    height: 17px;
    position: relative;
    top: 3px;
  }

  .info_con .t_s {
    font-size: 17px;
    color: #555;
  }

  .info_con .gs_box {
    width: 98%;
    font-size: 14px;
    padding: 30px 0px 10px 0px;
    // padding: 30px 20px 10px;
  }

  h2 {
    font-size: 1.5em;
  }

  .info_con .pro_xq {
    margin-bottom: 15px;
  }

  .info_con .pro_xq h2 {
    font-size: 18px;
    line-height: 30px;
    color: #000;
  }

  .info_con .pro_xq div {
    font-size: 16px;
    color: #555;
    line-height: 35px;
    text-align: justify;
  }

  .info_con table tr th,
  .info_con table tr td {
    border: 1px solid #d2d2d2;
  }
  .w130 {
    width: 130px;
    color: #656565;
    text-align: center;
  }

  .w470 {
    width: 470px;
    color: #333;
    text-align: center;
  }

  .w1070 {
    width: 1070px;
    color: #333;
    padding-left: 32px;
  }

  .basic_box label {
    font-size: 14px;
  }
  .basic_box table {
    width: 100%;
    min-height: 25px;
    line-height: 25px;
    margin-top: 18px;
    margin-bottom: 15px;
    border-color: #b6ff00;
    border-collapse: collapse;
  }

  .basic_box table td {
    font-size: 16px;
    padding-bottom: 15px;
    padding-top: 15px;
    line-height: 30px;
    vertical-align: middle;
    .center {
      text-align: center;
    }
  }

  .type_more {
    color: #b91d21;
    height: 30px;
    line-height: 38px;
    position: absolute;
    right: 10px;
    font-size: 12px;
  }

  .pro_name .xq_title i.state {
    background: url(../../assets/images/xq_ico1.png) no-repeat;
  }

  .pro_name .xq_title i {
    width: 65px;
    height: 25px;
    display: inline-block;
    background: #f00;
    position: relative;
    top: -2px;
    left: 15px;
    text-indent: 15px;
    line-height: 22px;
    font-size: 10px;
    color: #fff;
  }
}
</style>