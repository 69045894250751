<template>
  <div class="news-center">
    <p class="new_txt">新闻资讯</p>
    <div class="news_content">
      <b-card no-body>
        <b-tabs card>
          <b-tab
            :title="item"
            :active="active == index"
            v-for="(item, index) in tabsData"
            :key="index"
            @click="getList(index ? 4 : 3)"
          >
            <div class="list fs">
              <div
                class="list_item fs"
                v-for="i in listData"
                :key="i.id"
                @click="toDetail(i.id)"
              >
                <div class="images">
                  <img :src="i.header" />
                </div>
                <div class="r_content">
                  <div class="title">
                    {{ i.title }}
                  </div>
                  <p v-html="i.description"></p>
                  <div class="button"><span>查看详情</span></div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { articlePage } from '@/api/api'
export default {
  data () {
    return {
      tabsData: ['最新资讯', '信托百科'],
      active: 0,
      listData: []
    }
  },
  methods: {
    getList (cateId) {
      articlePage({
        cateId,
        page: 1,
        pageSize: 8,
      }).then(res => {
        this.listData = res.content || []
      })
    },
    toDetail (id) {
      this.$router.push({ path: '/news/detail', query: { id } })
    }
  },
  created () {
    this.getList(3)
  },
}
</script>

<style lang="scss" scoped>
::v-deep .card {
  border: none;
  border-radius: 0;
  .tabs {
    .card-header {
      padding: 0px 0px;
      border-radius: 0;
      background: #aaa;
    }
    .card-header-tabs {
      margin: 0;
    }
    .nav-link {
      width: 145px;
      height: 35px;
      text-align: center;
      padding: 0;
      border-radius: 0px;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      line-height: 35px;
      border: none;
      display: inline-block;
      &.active {
        background: #898989;
      }
    }
    .card-body {
      padding: 50px 0 0 0;
    }
  }
}
.news-center {
  margin-top: 30px;
  width: 100%;
  border: 1px #aaa solid;
  .new_txt {
    width: 100%;
    height: 67px;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: #4c4a48;
    background: #e5e5e5;
    line-height: 67px;
    letter-spacing: 5px;
  }
  .news_content {
    .list {
      flex-wrap: wrap;
      .list_item {
        flex: 0 0 50%;
        align-items: flex-start;
        margin-bottom: 30px;
        cursor: pointer;
        padding: 0px 20px;
        box-sizing: border-box;
        .images {
          flex: 0 0 185px;
          img {
            width: 100%;
          }
        }
        .r_content {
          padding-left: 15px;
          .title {
            font-size: 17px;
            line-height: 25px;
            width: 290px;
            overflow: hidden;
            color: #696667;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover {
              color: #be181f;
            }
          }
          p {
            padding-top: 8px;
            height: 90px;
            font-size: 13px;
            color: #666;
            line-height: 20px;
            overflow: hidden;
          }
          .button {
            text-align: right;
            span {
              display: inline-block;
              text-align: center;
              font-size: 14px;
              width: 78px;
              height: 23px;
              line-height: 23px;
              color: #e85603;
              border: 1px solid #e85603;
              cursor: pointer;
              border-radius: 12px;
              &:hover {
                background: #be181f;
                color: #fff;
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>