<template>
  <div>
    <Banner :url="bannerUrl"></Banner>
    <b-container fluid="xl">
      <Filters @search="search"></Filters>
      <TableData ref="table"></TableData>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Banner from '@/components/Banner'
import Filters from './components/Filters'
import TableData from './components/TableData'
export default {
  data () {
    return {
    }
  },
  components: {
    Banner,
    Filters,
    TableData
  },
  computed: {
    ...mapState(['searchPrdValue', 'siteData']),
    bannerUrl () {
      let url = ''
      let { pageBanner } = this.siteData
      let arr = pageBanner && pageBanner.length ? JSON.parse(pageBanner) : []
      arr = arr.map(i => typeof i == 'object' ? i : { image: i })
      url = arr[0] ? arr[0].image : ''
      return url
    }
  },
  beforeRouteLeave (to, from, next) {
    from.meta.keepAlive = to.name == 'productDtail'
    next()
  },
  mounted () {
    // setTimeout(() => {
    //   document.documentElement.scrollTop = 0;
    // }, 500)
  },
  created () {
  },
  methods: {
    search (data) {
      this.$refs.table.search(data)
    }
  }
}
</script>
<style lang="less" scoped>
</style>