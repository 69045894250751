<template>
  <div class="news-list">
    <b-container fluid="xl">
      <div
        class="list_item fs"
        v-for="i in listData"
        :key="i.id"
        @click="toDetail(i.id)"
      >
        <div class="images">
          <img :src="i.header" />
        </div>
        <div class="r_content">
          <div class="title">
            {{ i.title }}
          </div>
          <p>{{ i.description }}</p>
          <div class="time">{{ i.time }}</div>
        </div>
      </div>
      <div class="empty fcc" v-if="loaded && !pagination.total">
        <img :src="emptyIco">
        <div class="text">暂无数据</div>
      </div>
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.total"
        v-else
        :per-page="pagination.pageSize"
        align="right"
        @change="pageChange"
        :hide-goto-end-buttons="true"
        prev-text="上一页"
        next-text="下一页"
      ></b-pagination>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { articlePage } from '@/api/api'
export default {
  data() {
    return {
      listData: [],
      emptyIco: require('@/assets/images/search_empty.png'),
      loaded: false,
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0
      },
    }
  },
  created() {
    this.getList()
  },
  computed: {
    ...mapState(['searchPrdValue']),
  },
  watch: {
    searchPrdValue: {
      handler () {
        this.getList()
      }
    }
  },
  methods: {
    getList () {
      this.loaded = false;
      articlePage({
        cateId: 3,
        name: this.searchPrdValue,
        ...this.pagination,
      }).then(res => {
        this.loaded = true;
        this.listData = res.content.slice(0, 5) || []
        this.pagination.total = res.total
      })
    },
    toDetail (id) {
      this.$router.push({ path: '/news/detail', query: { id } })
    },
    pageChange (value) {
      this.pagination.page = value
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.news-list {
  padding: 30px 0;
    .list_item {
      border-bottom: 1px dashed #d0d0d0;
      padding-bottom: 15px;
      margin-bottom: 15px;
      .images {
        border: 2px solid #c9c9c9;
        margin: 5px 20px 0 0;
        img {
          width: 140.8px;
          display: block;
        }
      }
      .r_content {
        flex: 1;
        .title {
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
          color: #4c4a48;
          cursor: pointer;
          margin-bottom: 10px;
          &:hover {
            color: #ea5504;
          }
        }
        p {
          font-size: 14px;
          line-height: 23px;
          color: #4c4a48;
        }
        .time {
          color: #717171;
          font-size: 14px;
          margin-top: 5px;
          text-align: right;
        }
      }
    }
    .empty {
      padding: 30px 0;
      img {
        width: 200px;
      }
      .text {
        font-size: 16px;
        color: #ccc;
        margin-top: 30px;
      }
    }
    ::v-deep .pagination {
      margin: 30px 0 50px 0;
      li {
        margin: 0 5px;
        &.active {
          .page-link {
            background: #ea5504;
            border: 1px solid #ea5504;
            color: #fff;
          }
        }
        .page-link {
          padding: 0 15px;
          line-height: 32px;
          background: #fff;
          border: 1px solid #d9d9d9;
          box-shadow: none;
          margin: 0 5px;
          border-radius: 3px;
          color: #555;
          display: inline-block;
        }
      }
    }
  }
</style>