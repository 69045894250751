// 发行机构
export const institutions = ['中信信托', '华润信托', '兴业信托', '华融信托', '长安信托', '金谷信托', '英大信托', '光大信托', '中泰信托', '长城信托', '华宸信托', '平安信托', '华能信托', '民生信托', '西藏信托', '五矿信托', '山东信托', '陕西国投', '国元信托', '国联信托', '万向信托', '吉林信托', '浙金信托', '重庆信托', '上海信托', '中铁信托', '渤海信托', '中建投信托', '杭州信托', '苏州信托', '方正信托', '东莞信托', '大业信托', '建信信托', '中诚信托', '外贸信托', '百瑞信托', '粤财信托', '国投信托', '华鑫信托', '昆仑信托', '天津信托', '云南信托', '江苏信托', '雪松信托', '中航信托', '北京信托', '华宝信托', '西部信托', '爱建信托', '厦门信托', '紫金信托', '中粮信托', '山西信托', '中融信托', '交银信托', '中海信托', '中原信托', '湖南信托', '陆家嘴信托', '北方信托', '国民信托', '华澳信托', '新时代信托', '新华信托', '华信信托', '安信信托', '四川信托']
// 付息方式
export const payTypes = ['季度付息', '半年付息', '年付息', '成立满一年付息', '到期付息']
// 产品收益筛选
export const benefits = ['6%以下', '6%(含)-7%', '7%(含)-9%', '9%(含)以上']
export const zx_benefits = ['7%以下', '7%(含)-9%', '9%(含)-10%', '10%(含)以上']
// 项目所在地区
export const region = ['北京', '上海', '天津', '重庆', '广东', '福建', '海南', '安徽', '贵州', '甘肃', '广西', '河北', '河南', '黑龙江', '湖北', '湖南', '吉林', '江苏', '江西', '辽宁', '内蒙古', '宁夏', '青海', '山东', '山西', '陕西', '四川', '西藏', '新疆', '云南', '浙江']
// 推荐位
export const recPositions = ['优质产品推荐', '本周优质产品', '热门产品推荐']
// 募集状态
export const productStatus = ['预热中', '募集中', '已结束']
// 产品期限
export const dateRanges = ['12个月以内(含)', '12个月-24个月(含)', '24个月以上']
// 投资方向
export const directions = ['政信项目', '工商企业', '房地产', '金融市场', '资金池', '其他']