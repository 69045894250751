<template>
  <div v-if="url">
    <img :src="url">
  </div>
</template>

<script>
export default {
  props: ['url']
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
</style>