import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Product from '../views/Product'
import ProductDetail from '../views/Product/detail'
import News from '../views/News'
import NewsList from '../views/News/list'
import NewsDetail from '../views/News/detail'
import Company from '../views/Company'
import CompanyDetial from '../views/Company/detail'
import ContactUs from '../views/AboutUs/contact'
import AboutUsDt from '../views/AboutUs/detail'
import Evaluation from '../views/Evaluation'
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {}
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/zx_product',
    name: 'zxProduct',
    component: Product,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/product/detail',
    name: 'productDtail',
    component: ProductDetail,
    meta: {}
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: {}
  },
  {
    path: '/news/list',
    name: 'newsList',
    component: NewsList,
    meta: {}
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs,
    meta: {}
  },
  
  {
    path: '/evaluation',
    name: 'evaluation',
    component: Evaluation,
    meta: {}
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: AboutUsDt,
    meta: {}
  },
  {
    path: '/news/detail',
    name: 'newsDetail',
    component: NewsDetail,
    meta: {}
  },
  {
    path: '/company',
    name: 'company',
    component: Company,
    meta: {}
  },
  {
    path: '/company/detail',
    name: 'companyDetail',
    component: CompanyDetial,
    meta: {}
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return to.meta.keepAlive ? {} : { x: 0, y: 0 }
  }
})

// const VueRouterPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (to) {
//   return VueRouterPush.call(this, to).catch(err => err)
// }

export default router
