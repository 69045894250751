<template>
  <div
    class="contact-us"
    v-show="show"
  >
    <div class="wrapper">
      <span
        class="fixed_close"
        @click="close"
      >×</span>
      <div class="contact_phone"><b>精选产品，咨询热线 {{ siteData.phone }}</b></div>
      <p>火爆预约中，请留下您的联系方式，专业理财经理将尽快联系您）</p>
      <div class="submit_form fe">
        <input
          placeholder="请输入您的姓名"
          v-model="formData.userName"
          class="name"
        />
        <input
          placeholder="请输入您的手机号"
          v-model="formData.phoneNum"
          class="phone"
        />
        <div class="msg_code fs">
          <input
            placeholder="请输入验证码"
            v-model="formData.veriCode"
          >
          <button @click="sendMsg">{{ msgTime ? msgTime : '发送验证码' }}</button>
        </div>
        <div
          class="submit_btn"
          @click="submit"
        >提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validate } from '@/utils'
import { addAuthInfo, sendMessage } from '@/api/api'
const formData = {
  userName: '',
  phoneNum: '',
  veriCode: ''
}
export default {
  data () {
    return {
      show: true,
      msgTime: 0,
      formData: {
        ...formData
      }
    }
  },
  computed: {
    ...mapState(['siteData']),
  },
  methods: {
    close () {
      this.show = false
    },
    sendMsg () {
      let regPhone = /^1[3456789]\d{9}$/
      if (!regPhone.test(this.formData.phoneNum)) {
        return this.$bvModal.msgBoxOk('请输入正确手机号', {
          title: '提示',
          // centered: true,
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      if (this.msgTime) return
      sendMessage(this.formData.phoneNum).then(() => {
        this.msgTime = 60
        this.countDown()
      })
    },
    countDown () {
      setTimeout(() => {
        this.msgTime--
        if (!this.msgTime) return
        this.countDown()
      }, 1000)
    },
    submit () {
      let errMsg = validate(this.formData)
      if (errMsg) {
        return this.$bvModal.msgBoxOk(errMsg, {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      addAuthInfo(this.formData).then(() => {
        this.$bvModal.msgBoxOk('提交成功', {
          title: '提示',
          size: 'sm',
          centered: true,
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
        // this.$bvToast.toast('提交成功', {
        //   title: `提示`,
        //   variant: 'success',
        //   noCloseButton: true,
        //   toaster: 'b-toaster-top-center',
        //   append: false,
        //   autoHideDelay: 2000,
        //   solid: true
        // })
        this.$store.commit('setAuthRes', { createTime: Date.now(), ...this.formData })
        this.formData = {
          ...formData
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-us {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(../assets/images/y1.png) no-repeat center bottom;
  z-index: 99;
  color: #fff;
  opacity: 1;
  .wrapper {
    max-width: 980px;
    padding: 20px 0px;
    margin: 0 auto;
    position: relative;
    .fixed_close {
      position: absolute;
      right: -20px;
      top: 5px;
      font-size: 24px;
      cursor: pointer;
    }
    .contact_phone {
      font-size: 25px;
      font-weight: 700;
      color: #ec6941;
      line-height: 35px;
    }
    p {
      font-size: 12px;
      color: #fff;
      line-height: 22px;
    }
    .submit_form {
      input {
        font-size: 13px;
        border: 0;
        height: 40px;
        line-height: 36px;
        text-indent: 15px;
        margin-right: 5px;
        outline: 0;
        color: #858280;
        &.name {
          width: 160px;
          background: url(../assets/images/y5.png) no-repeat;
          background-size: 95% 100%;
        }
        &.phone {
          width: 210px;
          background: url(../assets/images/y4.png) no-repeat;
          background-size: 95% 100%;
        }
      }
      .msg_code {
        input {
          margin: 0;
          width: 90px;
          text-align: left;
          text-indent: 10px;
          padding: 0px 0px;
        }
        button {
          border: none;
          min-width: 77px;
          height: 40px;
          font-size: 13px;
          cursor: pointer;
          background: #e85603;
          color: #fff;
        }
      }
      .submit_btn {
        width: 132px;
        height: 40px;
        background: #b91d21;
        text-indent: 0;
        color: #fff;
        font-size: 16px;
        margin-left: 15px;
        cursor: pointer;
        background: url(../assets/images/y2.png) no-repeat;
        line-height: 36px;
        text-align: center;
        background-size: 100%;
      }
    }
  }
}
</style>