<template>
  <div class="top-header">
    <div class="site_head fc">
      <div class="fb">
        <span>您好，欢迎来到{{ siteData.name }}官方网站！</span>
        <span class="fs"><img
            src="../../assets/images/bt01.png"
            alt=""
          >{{ siteData.phone }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['siteData']),
  }
}
</script>

<style lang="scss">
.top-header {
  .site_head {
    height: 30px;
    background: #e6e6e6;
    margin: 0 auto;
    font-size: 12px;
    line-height: 30px;
    .fb {
      max-width: 1200px;
      width: 100%;
      span {
        &:first-child {
          color: #666;
        }
        &:last-child {
          font-size: 16px;
          font-weight: bold;
          color: #e85606;
          text-align: right;
          line-height: 30px;
          img {
            width: 13.5px;
            height: 18.62px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>