<template>
  <div class="news-page">
    <Banner :url="bannerUrl"></Banner>
    <b-container fluid="xl">
      <div class="search_nav fs"><img src="../../assets/images/arrow_ico.png"> <a @click="toHome">首页</a>-新闻资讯</div>
      <div class="fb">
        <LeftWrapper></LeftWrapper>
        <RightWrapper></RightWrapper>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Banner from '@/components/Banner'
import LeftWrapper from './components/LeftWrapper'
import RightWrapper from './components/RightWrapper'
export default {
  components: {
    Banner,
    LeftWrapper,
    RightWrapper
  },
  computed: {
    ...mapState(['siteData']),
    bannerUrl () {
      let url = ''
      let { pageBanner } = this.siteData
      let arr = pageBanner && pageBanner.length ? JSON.parse(pageBanner) : []
      arr = arr.map(i => typeof i == 'object' ? i : { image: i })
      url = arr[2] ? arr[2].image : ''
      return url
    }
  },
  mounted () {
  },
  methods: {
    toHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.news-page {
  background: #eee;
  padding-bottom: 50px;
  overflow: hidden;
  .fb {
    align-items: flex-start;
  }
  .search_nav {
    width: 100%;
    height: 40px;
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 10px;
    img {
      display: inline-block;
      position: relative;
      top: 2px;
      margin-left: 20px;
      width: 14px;
      height: 15px;
      margin-right: 5px;
    }
    a {
      color: #3d3a3b;
      cursor: pointer;
    }
  }
  .wrapper {
    border-top: 8px solid #e95604;
    background: #fff;
    padding-bottom: 20px;
  }
}
</style>