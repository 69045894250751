<template>
  <div class="hot-goods">
    <div class="top_txt fc">
      <h1>热门产品推荐</h1>
      <span @click="showMore"><a>查看更多产品&gt;&gt;</a></span>
    </div>
    <div class="good_list">
      <ul class="fs">
        <li
          :class="['fl', 'y_line_c', (index + 1) % 3 == 0 && 'noMargin']"
          v-for="(i,index) in productList"
          :key="i.id"
        >
          <div class="h_red"><img
              width="108"
              height="102"
              src="../../../assets/images/red_01.png"
            >
          </div>
          <h2 @click="contact(i)">{{ i.productName }}</h2>
          <div class="xm_sm fb">
            <div class="fl">
              <span class="xm_sm_t2">业绩基准</span>
              <span class="xm_sm_t1">{{ i.yield }}%/年</span>
            </div>
            <div class="fl jl">
              <span class="xm_sm_t2">投资方向</span>
              <span class="xm_sm_t1">{{ directions[i.direction - 1] }}</span>
            </div>
            <div class="fl">
              <span class="xm_sm_t2">投资期限</span>
              <span class="xm_sm_t1">{{ i.productTerm }}个月</span>
            </div>
          </div>
          <div class="pro_desc">{{ i.productAbstract }}</div>
          <a
            title="查看详情"
            class="pro_yuyue"
            @click="contact(i)"
          >查看详情</a>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AuthModal from '@/plugins/AuthModal'
import { getProductList } from '@/api/api'
import { productStatus, directions } from '@/utils/options'
export default {
  data () {
    return {
      productList: [],
      productStatus, directions
    }
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapState(['authRes'])
  },
  methods: {
    getList () {
      getProductList({
        recPosition: 3,
        page: 1,
        productType: 2,
        pageSize: 6
      }).then(({ content, total }) => {
        this.productList = content
      })
    },
    showMore () {
      this.$router.push('/zx_product')
    },
    toDetail (id) {
      this.$router.push({
        path: '/product/detail',
        query: {
          id
        }
      })
    },
    contact ({ id, productName }) {
      if (this.authRes && Object.keys(this.authRes).length) return this.toDetail(id)
      AuthModal({
        productName,
        successCb: () => {
          this.toDetail(id)
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.hot-goods {
  margin-top: 20px;
  .good_list {
    position: relative;
    margin-top: 50px;
    .h_red {
      position: absolute;
      width: 26%;
      height: 105px;
      top: -10px;
      left: -8px;
      display: block;
      img {
        width: 100%;
        height: auto;
      }
    }
    ul {
      flex-wrap: wrap;
      li {
        position: relative;
        flex: 0 0 28%;
        max-width: 28%;
        height: 257px;
        margin-bottom: 50px !important;
        margin-left: 29px;
        margin-right: 29px;
        border-radius: 7px;
        padding: 19px 3% 20px 3%;
        box-shadow: 0 1px 20px 0 rgba(66, 66, 66, 0.35);
        -moz-box-shadow: 0 1px 20px 0 rgba(66, 66, 66, 0.35);
        -webkit-box-shadow: 0 1px 20px 0 rgba(66, 66, 66, 0.35);
        &.noMargin {
          margin-right: 0;
        }
        h2 {
          font-size: 20px;
          letter-spacing: 1px;
          text-align: center;
          font-weight: bold;
          color: #4c4a48;
          width: 100%;
          cursor: pointer;
          font-size: 16px;
          padding: 0 6%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:hover {
            color: #ec6a41;
          }
        }
      }
    }
    .xm_sm {
      width: 96%;
      overflow: hidden;
      padding: 10px 2%;
      border-bottom: 1px solid #d0d0d0;
      border-top: 1px solid #d0d0d0;
      margin: 19px 0 3px;
    }
    .xm_sm .xm_sm_t1 {
      color: #fb6436;
      line-height: 25px;
      font-size: 15px;
      margin-top: 5px;
      // font-weight: bold;
    }

    .xm_sm .xm_sm_t2 {
      color: #676767;
      font-size: 15px;
    }

    .xm_sm span {
      text-align: center;
      font-size: 13px;
      display: block;
    }

    .xm_sm .jl {
      // margin: 0 15%;
    }
    .pro_desc {
      padding: 0 2px;
      font-size: 12px;
      color: #676767;
      line-height: 22px;
      height: 78px;
      text-align: justify;
    }
    .pro_yuyue {
      text-align: center;
      margin: 0 auto;
      width: 55%;
      padding: 8px 0;
      background: #e5e5e5;
      color: #ec6a41;
      font-size: 15px;
      display: block;
      border-radius: 20px;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: #fff;
        background: #ec6a41;
      }
    }
  }
}
</style>