<template>
  <b-navbar
    toggleable="lg"
    type="light"
    sticky
  >
    <b-container fluid="xl">
      <b-navbar-brand class="fc logo"><img
          :src="siteData.logo"
          @click="toHome"
        ></b-navbar-brand>
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav>
          <b-nav-item
            v-for="(item, index) in navList"
            :active="routeName == item.name"
            :key="item.name + index"
            @click="jumpTo(item.name)"
          >{{item.text}}</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <div class="search_input fc">
              <b-form-input
                size="sm"
                class="mr-sm-2"
                v-model="searchVal"
                placeholder="请输入相关内容搜索"
              ></b-form-input>
              <span
                class="button"
                @click="search"
              ></span>
            </div>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  data () {
    return {
      searchVal: ''
    }
  },
  mounted () {
    this.$store.commit('setSearchValue', '')
  },
  computed: {
    ...mapState(['siteData']),
    ...mapGetters(['cateShowObj', 'cateSortObj', 'siteId', 'aboutId']),
    navList () {
      let obj = this.cateShowObj
      let showkey = Number(this.siteId) - 1
      let sortObj = this.cateSortObj
      if (!Object.keys(obj).length) return [];
      const navArr = [
        {
          text: '首页',
          name: 'home',
          status: 1,
          sort: 0
        },
        {
          text: '信托产品',
          name: 'product',
          status: obj[1][showkey],
          sort: sortObj[1],
          id: 1
        },
        {
          text: '政信产品',
          name: 'zxProduct',
          status: obj[2][showkey],
          sort: sortObj[2],
          id: 2
        },
        {
          text: '新闻资讯',
          name: 'news',
          status: obj[3][showkey] || obj[4][showkey],
          sort: sortObj[3],
          id: 3
        },
        {
          text: '信托公司',
          name: 'company',
          status: obj[5][showkey],
          sort: sortObj[5],
          id: 5
        },
        {
          text: '关于我们',
          name: 'aboutUs',
          status: obj[this.aboutId][showkey],
          sort: sortObj[this.aboutId],
          id: this.aboutId
        },
        {
          status: obj[10][showkey],
          name: 'evaluation',
          sort: sortObj[10],
          text: '测评',
        },
      ]
      return navArr.filter(i => i.status).sort((a, b) => a.sort - b.sort)
    },
    // navList () {
    //   let ids = navArr.map(i => i.id)
    //   let obj = {}
    //   this.cateList.forEach(i => {
    //     let { id, status, sort } = i
    //     if (ids.includes(Number(id))) {
    //       obj[id] = { status, sort }
    //     }
    //   })
    //   let arr = navArr.map(i => {
    //     return obj[i.id] ? { ...i, ...obj[i.id] } : i
    //   }).filter(i => i.status)
    //   let arr2 = arr.sort((a, b) => a.sort - b.sort)
    //   return arr2
    // },
    routeName () {
      return this.$route.name
    }
  },
  methods: {
    toHome () {
      location.href = location.origin
    },
    search () {
      this.$store.commit('setSearchValue', this.searchVal)
      if (['product', 'zxProduct'].includes(this.$route.name)) return
      this.$router.push({
        path: this.siteId == 5 ? '/news/list' : '/product',
      })
    },
    jumpTo (name) {
      if (this.routeName == name) return;
      let query = ['product', 'zxProduct'].includes(name) ? {
        t: Date.now()
      } : {}
      this.$router.push({
        name,
        query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 110px;
  overflow: hidden;
  border-bottom: 1px #e5e5e5 solid;
  background: #fff;
  padding: 0;
  .logo {
    width: 20%;
    height: 110px;
    padding: 0;
    cursor: pointer;
    img {
      width: 100%;
      height: auto;
    }
  }
  .navbar-nav {
    .nav-item {
      margin: 0 20px;
      a {
        color: #333;
        padding: 0;
        font-weight: 700;
        font-size: 19px;
        &.active {
          color: #be181f;
        }
      }
    }
  }
  .search_input {
    position: relative;
    input {
      width: 203px;
      height: 35px;
      padding: 0 0;
      border: 1px solid #a1a1a1;
      border-radius: 20px;
      color: #a1a1a1;
      font-size: 14px;
      padding-left: 20px;
      box-sizing: border-box;
      &:focus {
        box-shadow: none;
      }
    }
    .button {
      position: absolute;
      left: 176px;
      width: 16px;
      height: 17px;
      cursor: pointer;
      background: transparent url("../../assets/images/search.png") no-repeat
        center/cover;
    }
  }
}
</style>