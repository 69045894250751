<template>
  <div class="table-data">
    <b-table
      :items="tableList"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      hover
      :busy="loading"
      sort-icon-left
      responsive="sm"
      outlined
    >
      <template v-slot:cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(productName)="data">
        <div
          class="pointer"
          @click="toDetail(data.item)"
        >{{ data.item.productName }}</div>
      </template>
      <template #cell(operation)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <b-button
          variant="outline-primary"
          class="prview_btn"
          @click="toDetail(data.item)"
        >查看详情</b-button>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>&nbsp;加载中...</strong>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-model="pagination.page"
      :total-rows="pagination.total"
      :per-page="pagination.pageSize"
      align="right"
      @change="pageChange"
      :hide-goto-end-buttons="true"
      prev-text="上一页"
      next-text="下一页"
    ></b-pagination>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AuthModal from '@/plugins/AuthModal'
import { getProductList } from '@/api/api'
import { productStatus, directions, payTypes, benefits } from '@/utils/options'
export default {
  data () {
    return {
      searchParams: {},
      pagination: {
        page: 1,
        pageSize: 20,
        total: 0
      },
      loading: false,
      sortBy: 'age',
      sortDesc: false,
      rows: 100,
      perPage: 10,
      currentPage: 1,
      fields: [
        { key: 'index', label: '编号', sortable: false, class: 'wid100px' },
        { key: 'productName', label: '产品名称', sortable: false, tdClass: 'nameTd' },
        {
          key: 'productStatus', label: '募集状态', sortable: false, formatter: (value) => {
            return value ? productStatus[Number(value) - 1] : ''
          }
        },
        {
          key: 'yield', label: '业绩基准', sortable: false, tdClass: 'red', formatter: (value) => {
            return value ? value + '%/年' : ''
          }
        },
        {
          key: 'productTerm', label: '投资期限', sortable: false, formatter: (value) => {
            return value ? value + '个月' : ''
          }
        },
        {
          key: 'minMoney', label: '起投金额', sortable: false, formatter: (value) => {
            return value ? value + '万元' : ''
          }
        },
        {
          key: 'direction', label: '投资方向', sortable: false, formatter: (value) => {
            return value ? directions[Number(value) - 1] : ''
          }
        },
        {
          key: 'payWay', label: '付息方式', sortable: false, formatter: (value) => {
            return value ? payTypes[Number(value) - 1] : ''
          }
        },
        { key: 'operation', label: '操作', sortable: false, class: 'operation' },
      ],
      tableList: []
    }
  },
  computed: {
    ...mapState(['searchPrdValue']),
  },
  watch: {
    searchPrdValue: {
      handler () {
        this.getList()
      }
    }
  },
  methods: {
    search (data) {
      this.searchParams = data
      this.getList()
    },
    getList () {
      this.loading = true
      getProductList({
        ...this.pagination,
        ...this.searchParams,
        productType: this.$route.name == 'zxProduct' ? 2 : 1,
        productName: this.searchPrdValue,
      }).then(({ content, total }) => {
        this.tableList = content
        this.pagination.total = total
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
    },
    toDetail ({ id, productName }) {
      let authRes = this.$store.state.authRes
      if (authRes && Object.keys(authRes).length) return this.linkDetail(id)
      AuthModal({
        productName,
        successCb: () => {
          this.linkDetail(id)
        }
      })
    },
    linkDetail (id) {
      this.$router.push({ path: '/product/detail', query: { id } })
    },
    pageChange (value) {
      this.pagination.page = value
      this.getList()
    }
  },
  created () {
    this.getList()
  },
}
</script>

<style lang="scss" scoped>
::v-deep .b-table {
  border-color: #e9e9e9 !important;
  .wid100px {
    width: 100px;
  }
  .productName {
    width: 250px;
  }
  .operation {
    width: 140px;
    padding: 0 15px;
    vertical-align: middle;
  }
  th {
    color: #888;
    text-align: center;
    font-size: 16px;
    line-height: 39px;
    padding: 0;
    border-bottom: 1px solid #e9e9e9;
    font-weight: normal;
    &.sordTh {
      background-position: left calc(100% - 0px) center !important;
      // width: 10vw;
      // padding-left: 0px !important;
    }
  }
  tbody {
    tr:hover {
      .prview_btn {
        background-color: #ff8b65;
        color: #fff !important;
      }
    }
  }
  td {
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #e9e9e9;
    font-size: 14px;
    vertical-align: middle;
    letter-spacing: 1px;
    padding: 10px 0;
    &.nameTd {
      // color: #666;
      font-size: 15px;
      // font-weight: bold;
      width: 250px;
      vertical-align: middle;
      line-height: 28px;
    }
    &.red {
      color: #d73435;
      font-size: 15px;
      font-weight: bold;
    }
    .prview_btn {
      display: inline-block;
      width: 100%;
      height: 35px;
      line-height: 35px;
      padding: 0 0;
      text-align: center;
      color: #ff8b65 !important;
      border-radius: 0;
      font-size: 15px;
      border: 1px #ff8b65 solid;
      &:hover {
        background-color: #ff8b65;
        color: #fff !important;
      }
    }
  }
}
::v-deep .pagination {
  margin: 30px 0 50px 0;
  li {
    margin: 0 5px;
    &.active {
      .page-link {
        background: #ea5504;
        border: 1px solid #ea5504;
        color: #fff;
      }
    }
    .page-link {
      padding: 0 15px;
      line-height: 32px;
      background: #fff;
      border: 1px solid #d9d9d9;
      box-shadow: none;
      margin: 0 5px;
      border-radius: 3px;
      color: #555;
      display: inline-block;
    }
  }
}
.table-data {
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>