import request from '@api/request'
import store from '../store'

export function siteItem (data) {
    return request({
        url: '/site/common/item', method: 'post', data: {
            ...data
        }
    })
}

export function cateTree (data) {    // 文章分类树形
    return request({ url: '/cate/common/tree', method: 'post', data })
}

export function updateRecommend (data) {
    return request({ url: '/site/updateRecommend', method: 'post', data })
}

export function updateBanner (data) {
    return request({ url: '/site/updateBanner', method: 'post', data })
}

export function articlePage (data) {   // 文章分页
    return request({ url: '/article/common/page', method: 'post', data })
}

export function getArticleDt (data) {   // 文章单条
    return request({ url: '/article/common/item', method: 'post', data })
}

export function getHomeData (data) {   // 主页
    return request({
        url: '/index/common/index', method: 'post', data
    })
}


export function cateArticle (data) {   // 一级分类下的文章
    return request({ url: '/article/common/cateArticle', method: 'post', data })
}

export function getProductList (data) {   // 分页
    return request({
        url: '/products/page',
        method: 'post',
        data
    })
}

export function getProductDt (id) {   // 分页
    return request({
        url: `/products/get?id=${id}&visit=1`,
        method: 'get',
    })
}

export function addAuthInfo (params) {    // 提交认证留言
    let data = {
        ...store.state.deviceInfo,
        ...params
    }
    return request({ url: '/authUserInfo/add', method: 'post', data })
}

export function getCompanyList (data) {   // 分页
    return request({
        url: '/companyInfo/page',
        method: 'post',
        data
    })
}

export function addEvaluation (data) {   // 测评提交
    return request({
        url: '/evaluation/add',
        method: 'post',
        data
    })
}

export function getCompanyDt (id) {   // 分页
    return request({
        url: `/companyInfo/get?id=${id}`,
        method: 'get',
    })
}

export function sendMessage (phoneNum) {   // 发送信息
    return request({
        url: `common/getSmsCode`,
        method: 'post',
        data: {
            phoneNum,
            siteId: store.state.siteId
        }
    })
}