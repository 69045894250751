import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)


const store = new Vuex.Store({
    state: {
        siteId: process.env.VUE_APP_SITE_ID,
        siteData: {},
        productData: {},
        cateList: [],
        deviceInfo: {},
        searchPrdValue: '',
        homeModalObj: {
            show: true
        },
        authRes: null
    },
    getters: {
        siteId: state => state.siteId,
        aboutId: (state) => {
            switch (state.siteId) {
                case 2:
                  return 7;
                case 3:
                  return 8;
                case 4:
                  return 9
                default:
                  return 6;
              }
        },
        cateList: state => state.cateList,
        cateShowObj: (state) => {
            let obj = {}
            try {
                obj = state.cateList.reduce((exVal, current, index, arr) => {
                    return { ...(index == 1 ? { [current.id]: current.status } : exVal), [index == 1 ? arr[index - 1].id : arr[index].id]: index == 1 ? exVal.status : current.status }
                })
            } catch {
                obj = {}
            }
            return obj
        },
        cateSortObj: (state) => {
            let obj = {}
            try {
                obj = state.cateList.reduce((exVal, current, index, arr) => {
                    return { ...(index == 1 ? { [current.id]: current.status } : exVal), [index == 1 ? arr[index - 1].id : arr[index].id]: index == 1 ? exVal.sort : current.sort }
                })
            } catch {
                obj = {}
            }
            return obj
        },
        activeId: state => state.activeId,
        activeIndex: state => state.activeIndex,
        siteInfo: state => state.siteInfo,
        activeArticleId: state => state.activeArticleId,
        meta: state => state.meta,
    },
    mutations: {
        setSiteId (state, payload) {
            state.siteId = payload
        },
        setHomeModalObj (state, payload) {
            state.homeModalObj = payload
        },
        setSiteData (state, payload) {
            state.siteData = payload || {}
        },
        setProductData (state, payload) {
            state.productData = payload || {}
        },
        setDeviceInfo (state, payload) {
            state.deviceInfo = payload || {}
        },
        setAuthRes (state, payload) {
            state.authRes = payload
        },
        setSearchValue (state, payload) {
            state.searchPrdValue = payload
        },
        setCateList (state, payload) {
            payload.forEach(i => {
                let { status } = i
                let arr = status && typeof status == 'string' ? status.split(',') : [status, 1, 1]
                arr = arr.concat(Array(5 - arr.length).fill(1))
                i.status = arr.map(i => Number(i))
            })
            state.cateList = payload
        },
        SET_SITEINFO (state, payload) {
            state.siteInfo = payload
        },
        SET_ACTIVEID (state, payload) {
            state.activeId = payload.toString()
        },
        SET_ACTIVEIDDEX (state, payload) {
            state.activeIndex = payload
        },
        SET_ACTIVEARTIVLEID (state, payload) {
            state.activeArticleId = payload
        },
        SET_META (state, payload) {
            state.meta = payload
        }
    },
    actions: {},
    modules: {},
    plugins: [
        createPersistedState({
            key: 'store'
        })
    ]
})

export default store