<template>
  <div class="contact-us">
    <div class="banner"></div>
    <b-container fluid="xl">
      <div class="container fb">
        <ul>
          <li class="fs">
            <img src="../../assets/images/contact1.png">
            <div>
              <p>全国热线</p>
              <p>{{ siteData.phone }}</p>
            </div>
          </li>
          <li class="fs">
            <img src="../../assets/images/contact2.png">
            <div>
              <p>公司邮箱</p>
              <p>{{ siteData.email }}</p>
            </div>
          </li>
          <li class="fs">
            <img src="../../assets/images/contact3.png">
            <div>
              <p>公司网址</p>
              <p>{{ origin }}</p>
            </div>
          </li>
          <li class="fs">
            <img src="../../assets/images/contact4.png">
            <div>
              <p>总部地址</p>
              <p>{{ siteData.address }}</p>
            </div>
          </li>
        </ul>
        <div
          class="map"
          id="map"
        ></div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      imgUrl: require('@/assets/images/about10.jpg')
    }
  },
  watch: {
    'siteData.lat': {
      handler () {
        this.createMap(Number(this.siteData.lat), Number(this.siteData.lng))
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(['siteData']),
    origin () {
      return location.origin
    }
  },
  components: {
  },
  mounted () {
    this.$nextTick(() => {
      this.createMap(Number(this.siteData.lat), Number(this.siteData.lng))
    })
  },
  methods: {
    createMap (lat, long) {
      if (!lat || !long) return
      // 百度地图API功能
      var map = new BMapGL.Map("map");
      map.centerAndZoom(new BMapGL.Point(lat, long), 15);
      map.enableScrollWheelZoom(true);
      var new_point = new BMapGL.Point(lat, long);
      var marker = new BMapGL.Marker(new_point);  // 创建标注
      map.addOverlay(marker);              // 将标注添加到地图中
      map.panTo(new_point);
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-us {
  background: #efeff2;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.banner {
  width: 100%;
  height: 600px;
  z-index: -1;
  background: url(../../assets/images/banner_01.png) no-repeat center/cover;
  position: absolute;
  top: 0;
  left: 0;
}
.container {
  position: relative;
  z-index: 2;
  background: #fff;
  margin: 100px auto;
  padding: 50px 40px;
  ul {
    width: 330px;
    li {
      margin-bottom: 20px;
      img {
        width: 50px;
        margin-right: 10px;
      }
      div {
        p {
          &:first-child {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .map {
    flex: 1;
    min-width: 400px;
    height: 500px;
  }
}
</style>