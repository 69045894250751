<template>
  <div class="table-data">
    <div class="title fs">
      <span class="xtgs_t">68家信托公司资料</span>
      <i>（按热销信托排序）</i>
    </div>
    <b-table
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      responsive="sm"
      outlined
    >
      <template #cell(shortName)="data">
        <div
          class="pointer"
          @click="toDetail(data.item)"
        >{{ data.item.shortName }}</div>
      </template>
      <template #cell(companyName)="data">
        <div
          class="pointer"
          @click="toDetail(data.item)"
        >{{ data.item.companyName }}</div>
      </template>
      <template #cell(operation)="data">
        <!-- `data.value` is the value after formatted by the Formatter -->
        <b-button
          variant="outline-primary"
          class="prview_btn"
          @click="toDetail(data.item)"
        >查看详情</b-button>
      </template>
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { getCompanyList } from '@/api/api'
export default {
  data () {
    return {
      sortBy: 'age',
      sortDesc: false,
      fields: [
        { key: 'rank', label: '排名', sortable: false, class: 'wid100px' },
        { key: 'shortName', label: '信托公司简称', sortable: false, tdClass: 'nameTd' },
        { key: 'companyName', label: '信托公司全称', sortable: false },
        { key: 'establishDate', label: '成立日期', sortable: false },
        { key: 'registMoney', label: '注册资本', sortable: false },
        { key: 'ifList', label: '是否上市', sortable: false },
        { key: 'cityName', label: '公司所在地', sortable: false },
        { key: 'operation', label: '', sortable: false, class: 'operation' },
      ],
      items: []
    }
  },
  created () {
    getCompanyList({
      page: 1,
      pageSize: 1000
    }).then(({ records }) => {
      let arr = records.sort((a, b) => {
        return a.rank - b.rank
      })
      this.items = arr
    })
  },
  methods: {
    toDetail (data) {
      this.$router.push({
        path: '/company/detail',
        query: {
          id: data.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .b-table {
  border-color: #ccc !important;
  border-top: 2px #ff6a4f solid !important;
  .wid100px {
    width: 100px;
  }
  .operation {
    width: 140px;
  }
  th {
    color: #888;
    text-align: center;
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    padding: 0;
    color: #3f3f3f;
    border-bottom: 1px solid #ccc;
    font-weight: normal;
  }
  tbody {
    tr {
      &:nth-child(even) {
        background-color: #eee;
      }
      &:hover {
        border-left: solid 2px #ff6a4f;
        .prview_btn {
          background-color: #ff8b65;
          color: #fff !important;
        }
      }
    }
  }
  td {
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    letter-spacing: 1px;
    &.nameTd {
      color: #666;
    }
    .prview_btn {
      display: inline-block;
      width: 100%;
      height: 35px;
      line-height: 35px;
      padding: 0 0;
      text-align: center;
      color: #ff8b65 !important;
      border-radius: 0;
      font-size: 15px;
      border: 1px #ff8b65 solid;
      &:hover {
        background-color: #ff8b65;
        color: #fff !important;
      }
    }
  }
}

.table-data {
  padding-bottom: 50px;
  .title {
    .xtgs_t {
      height: 35px;
      text-indent: 38px;
      font-size: 24px;
      color: #ff6a4f;
      display: inline-block;
      margin: 28px 12px 25px 0;
    }
    i {
      font-size: 14px;
    }
  }
}
</style>