import Vue from "vue";
import store from '@/store'

class Instance {
  constructor(option) {
    this.vm = option;
    this.body = document.body;
  }
  render (_props, body = document.body) {
    this.body = body;
    const instance = new Vue({
      store,
      render: (h) =>
        h(this.vm, {
          // 组件 prop
          props: _props,
        }),
    });
    const component = instance.$mount();
    if (this.$el) {
      //如果组件存在,直接替换原组件dom
      this.replace(component.$el);
    } else {
      this.body.appendChild(component.$el);
    }
    this.$el = component.$el;
  }
  destroy () {
    this.$el.remove();
  }
  replace (newdom) {
    this.body.replaceChild(newdom, this.$el);
  }
}

const createInstance = (option = {}) => {
  //注册组件
  Vue.component(option.name, option);
  let instance = new Instance(option);
  return (props = {}, body = document.body) => instance.render(props, body);
};

export default createInstance;
