<template>
  <div>
    <TopHeader></TopHeader>
    <!-- <b-container fluid="xl"> -->
    <NavBar></NavBar>
    <!-- </b-container> -->
    <keep-alive>
      <!-- 需要缓存的视图组件 -->
      <router-view
        v-if="$route.meta.keepAlive"
        :key="$route.path + $route.query.t"
      ></router-view>
    </keep-alive>
    <!-- 不需要缓存的视图组件 -->
    <router-view
      v-if="!$route.meta.keepAlive"
      :key="$route.path + $route.query.t"
    ></router-view>
    <Footer></Footer>
    <ContactUs v-if="!(authRes && Object.keys(authRes).length) && Number(siteId) !== 5"></ContactUs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TopHeader from './TopHeader.vue'
import NavBar from './NavBar.vue'
import Footer from '@/components/Footer'
import ContactUs from '@/components/ContactUs'
export default {
  computed: {
    ...mapState(['authRes', 'siteId'])
  },
  components: {
    TopHeader,
    NavBar,
    Footer,
    ContactUs
  }
}
</script>