<template>
  <div>
    <b-modal
      id="modal-1"
      v-model="show"
      modal-class="auth-modal"
      :title="title || productName"
      :centered="true"
      :ok-only="true"
      :no-close-on-backdrop="true"
      :cancel-disabled="true"
      @close="close"
      ok-title="认证查看详情"
    >
      <div class="auth_form_item fs">
        <label>姓名 *</label>
        <input
          placeholder="请输入您的姓名"
          v-model="formData.userName"
        />
      </div>
      <div class="auth_form_item fs">
        <label>电话*</label>
        <input
          placeholder="请输入您的手机号"
          v-model="formData.phoneNum"
        />
      </div>
      <div class="auth_form_item fs">
        <label>验证*</label>
        <div class="txtCode fs">
          <input
            placeholder="请输入验证码"
            maxlength="6"
            v-model="formData.veriCode"
          />
          <span
            class="send_btn fc"
            @click="sendMsg"
          >{{ msgTime ? msgTime + '秒' : '发送验证码' }}</span>
        </div>
      </div>
      <div
        slot="modal-footer"
        @click="submit"
      >
        <button>认证查看详情</button>
      </div>
      <!-- <b-button
              size="sm"
              class="my-2 my-sm-0"
              type="submit"
            >Search</b-button> -->
    </b-modal>
  </div>
</template>

<script>
import { validate } from '@/utils'
import { addAuthInfo, sendMessage } from '@/api/api'
const formData = {
  userName: '',
  phoneNum: '',
  veriCode: ''
}
export default {
  name: "AuthModal",
  data () {
    return {
      show: true,
      msgTime: 0,
      authRes: false,
      formData: {
        ...formData
      }
    }
  },
  mounted () {
    // this.$bvModal.show('modal-1')
  },
  props: ['productName', 'articleId', 'title', 'closeCb', 'successCb'],
  methods: {
    close () {
      if (this.authRes) return;
      if (this.closeCb) return this.closeCb();
      // this.$router.go(-1)
    },
    sendMsg () {
      let regPhone = /^1[3456789]\d{9}$/
      if (!regPhone.test(this.formData.phoneNum)) {
        return this.$bvModal.msgBoxOk('请输入正确手机号', {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      if (this.msgTime) return
      sendMessage(this.formData.phoneNum).then(() => {
        this.$bvModal.msgBoxOk('验证码发送成功', {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
        this.msgTime = 60
        this.countDown()
      })
    },
    countDown () {
      setTimeout(() => {
        this.msgTime--
        if (!this.msgTime) return
        this.countDown()
      }, 1000)
    },
    submit () {
      let errMsg = validate(this.formData)
      if (errMsg) {
        return this.$bvModal.msgBoxOk(errMsg, {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      let params = {
        ...this.formData,
        productName: this.productName || '',
        articleId: this.articleId || '',
        siteId: this.$store.state.siteId
      }
      addAuthInfo(params).then(() => {
        this.$bvModal.msgBoxOk('提交成功', {
          title: '提示',
          size: 'sm',
          centered: true,
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        });
        this.$store.commit('setAuthRes', {
          createTime: Date.now(),
          ...this.formData
        })
        this.authRes = true
        this.show = false
        if (typeof this.successCb == 'function') {
          this.successCb()
        }
        // this.$bvToast.toast('提交成功', {
        //   title: `提示`,
        //   variant: 'success',
        //   noCloseButton: true,
        //   toaster: 'b-toaster-top-center',
        //   append: false,
        //   autoHideDelay: 2000,
        //   solid: true
        // })
        this.formData = {
          ...formData
        }
      })
    }
  }
}
</script>

<style lang="scss">
.auth-modal {
  .modal-dialog {
    width: 340px;
    .modal-header {
      justify-content: center;
      position: relative;
      h5 {
        font-size: 16px;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #e85603;
      }
      .close {
        position: absolute;
        top: 0;
        margin: 0 0;
        right: 0;
      }
    }
    .modal-body {
      padding-bottom: 0;
    }
    .modal-footer {
      border-top: none;
      padding-top: 0;
      justify-content: center;
      padding: 0px 40px 20px 40px;
      > div {
        width: 100%;
      }
      button {
        width: 98%;
        background-color: #e85603;
        color: #fff;
        border: 0;
        height: 2.5em;
        border-radius: 5px;
        font-size: 1.15em;
        font-size: 15px;
      }
    }
  }
}
.auth_form_item {
  width: 100%;
  margin-bottom: 20px;
  label {
    flex: 0 0 80px;
    text-align: right;
    padding-right: 10px;
    font-size: 15px;
  }
  input {
    flex: 0 0 184px;
    height: 35px;
    font-size: 14px;
    border: 1px solid #eee;
    background: #fff;
    line-height: 28px;
    text-indent: 15px;
    outline: medium;
  }
  .txtCode {
    flex: 0 0 184px;
    position: relative;
    .send_btn {
      position: absolute;
      top: 0;
      right: 0;
      width: 75px;
      font-size: 12px;
      cursor: pointer;
      white-space: nowrap;
      height: 100%;
      background: #e85603;
      color: #fff;
    }
    input {
      width: 110px;
      flex: inherit;
    }
  }
}
</style>