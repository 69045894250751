<template>
  <div class="evaluation-page">
    <Banner :url="bannerUrl"></Banner>
    <b-container fluid="xl">
      <div class="submit-form">
        <div class="submit_form_item fs">
          <label>姓名 *</label>
          <input
          required
            placeholder="请输入您的姓名"
            v-model="formData.name"
          />
        </div>
        <div class="submit_form_item fs">
          <label>性别 *</label>
          <b-form-radio-group
            id="radio-group-2"
            v-model="formData.gender"
            name="radio-sub-component"
          >
            <b-form-radio value="男">男</b-form-radio>
            <b-form-radio value="女">女</b-form-radio>
         </b-form-radio-group>
        </div>
        <div class="submit_form_item fs">
          <label>年龄 *</label>
          <input
            placeholder="请输入您的姓名"
            v-model="formData.age"
          />
        </div>
        <div class="submit_form_item fs">
          <label>预算 *</label>
          <b-form-select v-model="formData.budget">
            <b-form-select-option
              v-for="(item, index) in columns"
              :key="index"
              :value="item"
            >{{ item }}</b-form-select-option>
          </b-form-select>
        </div>
        <div class="submit_form_item fs">
          <label>电话*</label>
          <input
            placeholder="请输入您的手机号"
            v-model="formData.phone"
          />
        </div>
        <div class="submit_form_item fs">
          <label>验证*</label>
          <div class="txtCode fs">
            <input
              placeholder="请输入验证码"
              maxlength="6"
              v-model="formData.veriCode"
            />
            <span
              class="send_btn fc"
              @click="sendMsg"
            >{{ msgTime ? msgTime + '秒' : '发送验证码' }}</span>
          </div>
        </div>
        <div
          @click="submit"
        >
          <button class="submit_btn">提交</button>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { validateEvaluation } from '@/utils'
import { addEvaluation, sendMessage } from '@/api/api'
import Banner from '@/components/Banner'
const formData = {
  name: '',
    gender: '',
    age: '',
    budget: '',
    phone: '',
    veriCode: ''
}
export default {
  data() {
    return {
      columns: ['5万元以下', '5万-10万', '10万以上'],
      msgTime: 0,
      formData: {
        ...formData
      }
    }
  },
  components: {
    Banner
  },
  computed: {
    ...mapState(['siteData']),
    bannerUrl () {
      let url = ''
      let { pageBanner } = this.siteData
      let arr = pageBanner && pageBanner.length ? JSON.parse(pageBanner) : []
      arr = arr.map(i => typeof i == 'object' ? i : { image: i })
      url = arr[0] ? arr[0].image : ''
      return url
    }
  },
  methods: {
     sendMsg () {
      let regPhone = /^1[3456789]\d{9}$/
      if (!regPhone.test(this.formData.phoneNum)) {
        return this.$bvModal.msgBoxOk('请输入正确手机号', {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      if (this.msgTime) return
      sendMessage(this.formData.phoneNum).then(() => {
        this.$bvModal.msgBoxOk('验证码发送成功', {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
        this.msgTime = 60
        this.countDown()
      })
    },
    countDown () {
      setTimeout(() => {
        this.msgTime--
        if (!this.msgTime) return
        this.countDown()
      }, 1000)
    },
    submit () {
      let errMsg = validateEvaluation(this.formData)
      if (errMsg) {
        return this.$bvModal.msgBoxOk(errMsg, {
          title: '提示',
          size: 'sm',
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        })
      }
      let params = {
        ...this.formData
      }
      addEvaluation(params).then(() => {
        this.$bvModal.msgBoxOk('提交成功', {
          title: '提示',
          size: 'sm',
          centered: true,
          modalClass: 'tip-modal',
          okTitle: '知道了',
          autoHideDelay: 2000,
        });
 
        this.formData = {
          ...formData
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.evaluation-page {
  background: #f8f8f8;
  overflow: hidden;
  .submit-form {
    width: 800px;
    margin: 60px auto 60px auto;
    background: #fff;
    border-radius: 20px;
    padding: 30px 20px;
    :deep(.submit_form_item) {
      margin-bottom: 20px;
      label {
        flex: 0 0 20%;
        text-align: right;
        padding-right: 15px;
      }
      input, .custom-select {
        flex: 0 0 60%;
        height: 35px;
        font-size: 14px;
        border: 1px solid #eee;
        background: #fff;
        line-height: 28px;
        text-indent: 15px;
        outline: medium;
      }
      .custom-select {
        line-height: 23px;
      }
      .custom-control-label {
        line-height: 22px;
      }
      .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #e85603;
        background-color: #e85603;
      }
      .txtCode {
        flex: 0 0 60%;
        position: relative;
        .send_btn {
          position: absolute;
          top: 0;
          right: 0;
          width: 75px;
          font-size: 12px;
          cursor: pointer;
          white-space: nowrap;
          height: 100%;
          background: #e85603;
          color: #fff;
        }
        input {
          flex: 1;
        }
      }
    }
    button.submit_btn {
        width: 70%;
        margin-top: 20px;
        margin-left: 10%;
        background-color: #e85603;
        color: #fff;
        border: 0;
        height: 2.5em;
        border-radius: 5px;
        font-size: 1.15em;
        font-size: 15px;
      }
  }
}
</style>