<template>
  <div class="left-wrapper wrapper">
    <div class="fl xtgspm">
      <div class="xtgspm_t">
        <p><span>当前位置:</span><a>首页</a>»<a>信托公司</a>{{ data.companyName }}</p>
      </div>
      <div class="xtgsxq_con">
        <table
          cellspacing="0"
          cellpadding="0"
          class="xtgs_xqcon"
        >
          <tbody>
            <tr>
              <td class="w130">公司简称</td>
              <td class="w275">{{ data.shortName }}
              </td>
              <td class="w130">机构全称</td>
              <td class="w275">{{ data.companyName }}</td>
            </tr>
            <tr>
              <td class="w130">成立日期</td>
              <td class="w275">{{ data.establishDate }}</td>
              <td class="w130">所在地</td>
              <td class="w275">{{ data.cityName }}</td>
            </tr>
            <tr>
              <td class="w130">注册资本</td>
              <td class="w275">{{ data.registMoney }}亿元</td>
              <td class="w130">是否上市</td>
              <td class="w275">{{ data.ifList }}</td>
            </tr>
            <tr>
              <td class="w130">公司类型</td>
              <td class="w275">{{ data.companyName }}</td>
              <td class="w130">法人代表</td>
              <td class="w275">{{ data.legalName }}</td>
            </tr>
            <tr>
              <td class="w130">公司网址</td>
              <td class="w275">{{ data.siteUrl }}</td>
              <td class="w130">股东背景</td>
              <td class="w275">{{ data.background }}</td>
            </tr>
          </tbody>
        </table>
        <div class="clear">
          <br>
        </div>
        <table
          cellspacing="0"
          cellpadding="0"
          class="xtgs_xqcon"
        >
          <tbody>
            <tr>
              <td
                colspan="2"
                class="w815"
              >公司简介 </td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="h312"
              >
                <span
                  class="xtgs_jscon w-e-text-container"
                  id="style-1"
                >
                  <div
                    data-slate-editor
                    v-html="data.companyDesc"
                  ></div>
                </span>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data']
}
</script>

<style lang="scss" scoped>
.left-wrapper {
  padding: 20px 20px;
}
.xtgspm table {
  width: 100%;
  min-height: 25px;
  line-height: 25px;
  border-color: #b6ff00;
  border-collapse: collapse;
}

.xtgs_xqcon tr td {
  text-align: center;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  margin-top: -1px;
}

.xtgs_xqcon table td {
  font-size: 18px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.xtgs_xqcon td.w130 {
  width: 130px;
  color: #333;
}

.xtgs_xqcon td.w275 {
  width: 275px;
  color: #333;
}

.xtgs_xqcon td.w815 {
  width: 815px;
  height: 60px;
  text-align: center;
  color: #333;
  line-height: 60px;
}

.xtgs_xqcon td .xtgs_jscon {
  width: 808px;
  float: left;
  padding: 12px 15px;
  letter-spacing: 1px;
  text-align: left;
  text-indent: 36px;
  line-height: 26px;
  // height: 300px;
  // overflow-y: scroll;
  // overflow-x: hidden;
  font-size: 15px;
  text-align: justify;
}

.xtgspm_con {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 5px #e5e5e5;
  -webkit-box-shadow: 0 0 5px #e5e5e5;
  -moz-box-shadow: 0 0 5px #e5e5e5;
  -o-box-shadow: 0 0 5px #e5e5e5;
  margin-bottom: 16px;
}

.xtgspm_t {
  height: 46px;
  color: #333;
  font-size: 14px;
  line-height: 46px;
  text-indent: 8px;
  letter-spacing: 2px;
  border: 1px #c1bcbc solid;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.xtgspm_t p,
.xtgspm_t p a {
  color: #656565;
  line-height: 46px;
}

.xtgspm_t {
  height: 46px;
  color: #333;
  font-size: 14px;
  line-height: 46px;
  text-indent: 8px;
  letter-spacing: 2px;
  border: 1px #c1bcbc solid;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.xtgspm_t p,
.xtgspm_t p a {
  color: #656565;
  line-height: 46px;
}

.xtgspm_list {
  width: 100%;
}

.xtgspm_list li {
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  text-indent: 12px;
  border-left: 1px #c1bcbc solid;
  border-right: 1px #c1bcbc solid;
  border-top: 1px dashed #c1bcbc;
}
.xtgsxq_con table tr th,
.xtgsxq_con table tr td {
  border: 1px solid #d2d2d2;
}
</style>