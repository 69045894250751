<template>
  <div class="about-us">
    <b-container fluid="xl">
      <h2>{{ detailObj.title }}</h2>
      <div class="content w-e-text-container">
        <div
          data-slate-editor
          v-html="detailObj.detail"
        ></div>
      </div>
    </b-container>
  </div>
</template>
<script>
import { articlePage, getArticleDt } from '@/api/api'
export default {
  data () {
    return {
      detailObj: {}
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      // let { type } = this.$route.query
      let siteId = this.$store.state.siteId
      articlePage({
        cateId: siteId == 1 ? 6 : (siteId == 2 ? 7 : 8),
        // cateId: type == 1 ? 6 : 7,
        page: 1,
        pageSize: 1,
      }).then(res => {
        if (res.content && res.content.length) {
          getArticleDt({
            articleId: res.content[0].id
          }).then(data => {
            this.detailObj = data
          })
        }
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.about-us {
  text-align: center;
  padding: 50px 50px;
  h2 {
    margin-bottom: 30px;
    font-size: 38px;
  }
  .content {
    text-align: left;
    line-height: 28px;
    font-size: 18px;
  }
}
</style>