<template>
  <div class="company-page">
    <Banner :url="bannerUrl"></Banner>
    <b-container fluid="xl">
      <TableData></TableData>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Banner from '@/components/Banner'
import TableData from './components/TableData'
export default {
  computed: {
    ...mapState(['siteData']),
    bannerUrl () {
      let url = ''
      let { pageBanner } = this.siteData
      let arr = pageBanner && pageBanner.length ? JSON.parse(pageBanner) : []
      arr = arr.map(i => typeof i == 'object' ? i : { image: i })
      url = arr[1] ? arr[1].image : ''
      return url
    }
  },
  components: {
    Banner,
    TableData
  }
}
</script>

<style lang="scss" scoped>
.company-page {
}
</style>