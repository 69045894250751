<template>
  <div class="news-list">
    <div
      class="list_item fs"
      v-for="i in listData"
      :key="i.id"
      @click="toDetail(i.id)"
    >
      <div class="images">
        <img :src="i.header" />
      </div>
      <div class="r_content">
        <div class="title">
          {{ i.title }}
        </div>
        <p>{{ i.description }}</p>
        <div class="time">{{ i.time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { articlePage } from '@/api/api'
export default {
  data() {
    return {
      listData: [],
      pagination: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList () {
      articlePage({
        cateId: 3,
        page: 1,
        pageSize: 5,
      }).then(res => {
        this.listData = res.content.slice(0, 5) || []
        this.pagination.total = res.total
      })
    },
     toDetail (id) {
      this.$router.push({ path: '/news/detail', query: { id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.news-list {
  padding: 30px 0;
    .list_item {
      border-bottom: 1px dashed #d0d0d0;
      padding-bottom: 15px;
      margin-bottom: 15px;
      .images {
        border: 2px solid #c9c9c9;
        margin: 5px 20px 0 0;
        img {
          width: 140.8px;
          display: block;
        }
      }
      .r_content {
        flex: 1;
        .title {
          font-size: 20px;
          line-height: 30px;
          font-weight: 700;
          color: #4c4a48;
          cursor: pointer;
          margin-bottom: 10px;
          &:hover {
            color: #ea5504;
          }
        }
        p {
          font-size: 14px;
          line-height: 23px;
          color: #4c4a48;
        }
        .time {
          color: #717171;
          font-size: 14px;
          margin-top: 5px;
          text-align: right;
        }
      }
    }
  }
</style>